import React from "react";
import "../styles/termscondition.css";

const TermsConditions = () => {
  return (
    <div className="terms-main container-fluid min-vh-100 bg-light">
      <div className="row justify-content-center py-5 mt-5">
        <div
          className="col-lg-10 col-md-10 col-sm-12 bg-white p-4 shadow"
          style={{ borderRadius: "20px" }}
        >
          <h1 className="fw-bold fs-2 text-center mb-4 mt-4">
            Terms and Conditions
          </h1>
          <p>
            Welcome to{" "}
            <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
            <span style={{ fontWeight: "bold", color: "#00a5e0" }}>Ads</span>!
            By accessing or using our services, you agree to comply with the
            following terms and conditions. Please read them carefully.
          </p>
          <ol>
            <li className="fw-bold mb-2">General</li>

            <p>
              These Terms and Conditions ("Agreement") govern the use of
              services provided by{" "}
              <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
              <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                Ads
              </span>{" "}
              ("Company," "we," "us," or "our"). By using our services, you
              ("Client," "you") agree to the terms set forth in this document
            </p>

            <li className="fw-bold mb-2">Services Overview</li>

            <p>
              <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
              <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                Ads
              </span>{" "}
              provides various digital marketing and development services,
              including but not limited to:
              <ul>
                <li>
                  Graphic Design (Logo, visiting cards, email signatures, social
                  media Banners, etc.)
                </li>
                <li>Web Development and Management</li>
                <li>Social Media Marketing and Management</li>
                <li>SEO and AI SEO Optimization</li>
                <li>Custom Business Emails</li>
                <li>Online Ads (Google Ads, Meta Ads, LinkedIn Ads, etc.)</li>
                <li>App Development</li>
                <li>Hosting, Domain, SSL, and Backup Solutions</li>
              </ul>
              Specific services offered may vary depending on the plan selected
              (Silver Streak, Golden Glow, Platinum Power).
            </p>
            <li className="fw-bold">Graphic Design Services</li>
            <p>
              <ul>
                <li>
                  Each plan includes a set of graphic design services (logo,
                  visiting card, email signature, brand video, etc.). These are
                  provided only once per plan.
                </li>
                <li>
                  Renewing a plan does not entitle the client to new graphic
                  design content unless a new Graphic Design plan is purchased.
                </li>
                <li>
                  Custom graphic design services can be purchased separately if
                  additional work is required.
                </li>
              </ul>
            </p>
            <li className="fw-bold">Website Development</li>
            <p>
              <ul>
                <li>
                  Silver Streak Plan: Basic website development with limited
                  customization.
                </li>
                <li>
                  Golden Glow Plan: More advanced features and custom elements.
                </li>
                <li>
                  Platinum Power Plan: Full customization with advanced
                  integrations, including AI and other advanced tools.
                </li>
              </ul>
            </p>
            <li className="fw-bold">Social Media Management</li>
            <p>
              We provide social media management for platforms such as
              Instagram, Facebook, LinkedIn, X (formerly Twitter), and YouTube.
              The scope depends on the selected plan:
              <ul>
                <li>
                  <b>Silver Streak:</b> Basic management of a limited number of
                  platforms.
                </li>
                <li>
                  <b>Golden Glow:</b> Extended services with additional
                  platforms like WhatsApp Broadcast and YouTube Channel
                  Management.
                </li>
                <li>
                  <b>Platinum Power:</b> Full management with premium services,
                  including priority posting, YouTube management, and custom
                  strategies.
                </li>
              </ul>
            </p>
            <li className="fw-bold">Social Media Post Creation</li>
            <p>
              <ul>
                <li>
                  Social media posts are created as per the plan:
                  <div className="table-responsive">
                    <table
                      className="table table-bordered mt-3 text-center"
                      style={{ border: "black" }}
                    >
                      <thead>
                        <tr>
                          <th>Plan \ Time</th>
                          <th>1 Month</th>
                          <th>1 Year</th>
                          <th>2 Year + 1 Month</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="fw-bold">Silver Streak</td>
                          <td>4 Posts</td>
                          <td>48 Posts </td>
                          <td>100 Posts</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Golden Glow</td>
                          <td>4 Posts</td>
                          <td>48 Posts</td>
                          <td>100 Posts</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Platinum Power</td>
                          <td>8 Posts</td>
                          <td>96 Posts</td>
                          <td>200 Posts</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
                <li>Additional posts can be purchased upon request.</li>
              </ul>
            </p>
            <li className="fw-bold">Advertising Services</li>
            <p>
              <ul>
                <li>
                  We offer advertising services (Google Ads, Meta Ads, LinkedIn
                  Ads, etc.) under pre-agreed budgets.
                </li>
                <li>
                  The client is responsible for any extra advertising costs if
                  the budget exceeds the agreed amount.
                </li>
                <li>
                  Only performance reports will be shared, and no insights or
                  access to ad platforms will be provided to the client.
                </li>
              </ul>
            </p>
            <li className="fw-bold">Custom Business Emails</li>
            <p>
              We offer custom business emails with a 1GB storage limit per
              email. Any storage upgrades or additional services will incur an
              extra charge based on third-party pricing.
            </p>
            <li className="fw-bold">SEO and AI Integration</li>
            <p>
              <ul>
                <li>
                  AI-based SEO and product SEO services are included in the
                  Platinum Power Plan.
                </li>
                <li>
                  Lower-tier plans include basic SEO, with additional custom
                  services available upon request.
                </li>
              </ul>
            </p>
            <li className="fw-bold">App Development</li>
            <p>
              <ul>
                <li>
                  App development services are included only in the Platinum
                  Power Plan.
                </li>
                <li>
                  Separate app development packages can be purchased for Silver
                  Streak and Golden Glow plan holders.
                </li>
              </ul>
            </p>
            <li className="fw-bold">WhatsApp and YouTube Management</li>
            <p>
              <ul>
                <li>
                  <b>WhatsApp Management:</b> Includes setting up WhatsApp
                  Business accounts and broadcast channels (if requested).
                  Subscriber growth is not guaranteed.
                </li>
                <li>
                  <b>YouTube Management:</b> Includes channel setup, content
                  upload, and basic management. Subscriber and engagement growth
                  is not guaranteed.
                </li>
              </ul>
            </p>
            <li className="fw-bold">Hosting, Domain, SSL, and Backups</li>
            <p>
              <ul>
                <li>
                  <span style={{ fontWeight: "bold", color: "#26017b" }}>
                    Fuzz
                  </span>
                  <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                    Ads
                  </span>{" "}
                  uses Hostinger for hosting and domain services, including SSL
                  certificates and backups.
                </li>
                <li>
                  The cost of hosting and domains will be charged to the client,
                  and all invoices will be shared via business email.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", color: "#26017b" }}>
                    Fuzz
                  </span>
                  <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                    Ads
                  </span>{" "}
                  only use Hostinger for Hosting services and Domain Services
                  and have no partnership with them.
                </li>
              </ul>
            </p>
            <li className="fw-bold">MSME Registration (E-Udyam)</li>
            <p>
              <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
              <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                Ads
              </span>{" "}
              offers free assistance with MSME registration on the Government
              E-Udyam Portal as part of all our plans.
            </p>
            <li className="fw-bold">Project Delivery Timeline</li>
            <p>
              <ul>
                <li>
                  <b>Silver Streak:</b> 7-10 business days.
                </li>
                <li>
                  <b>Golden Glow:</b> 15-20 business days
                </li>
                <li>
                  <b>Platinum Power:</b> 1 to 1.5 months.
                </li>
                <li>
                  If changes are requested during project development, any
                  delays in deployment due to those changes are the client's
                  responsibility.
                </li>
                <li>
                  We follow the waterfall model for project development.
                  Therefore, if changes are requested after approval at any
                  stage of the project, additional charges will apply to
                  accommodate those changes.
                </li>
              </ul>
            </p>
            <li className="fw-bold">Reports and Video Meetings</li>
            <p>
              <ul>
                <li>
                  <b>Silver Streak:</b> Basic monthly reports.
                </li>
                <li>
                  <b>Golden Glow:</b> weekly performance reports.
                </li>
                <li>
                  <b>Platinum Power:</b> Daily reports and one-on-one
                  consultation via video calls.
                </li>
                <li>
                  Clients can schedule virtual meetings based on their plan
                  limits.
                </li>
              </ul>
            </p>
            <li className="fw-bold">Payments and Refunds</li>
            <p>
              <ul>
                <li>
                  Payments for all services are processed through Razorpay.
                  Clients are responsible for timely payments to avoid delays.
                </li>
                <li>
                  Refunds are only applicable within 24 hours of plan purchase.
                  Any fees charged by the payment gateway during the refund
                  process will be paid by the client.
                </li>
                <li>
                  Payment gateway integration with Razorpay is included in all
                  plans, but any issues related to Razorpay must be handled
                  directly.
                </li>
              </ul>
            </p>
            <li className="fw-bold">Support and Maintenance</li>
            <p>
              Basic support is provided for all plans. Platinum Power clients
              receive 24/7 priority support and a dedicated account manager.
            </p>
            <li className="fw-bold"> Intellectual Property</li>
            <p>
              <ul>
                <li>
                  All creative content (graphics, logos, websites, etc.)
                  developed by{" "}
                  <span style={{ fontWeight: "bold", color: "#26017b" }}>
                    Fuzz
                  </span>
                  <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                    Ads
                  </span>{" "}
                  remains the property of the client upon project completion,
                  unless otherwise agreed upon.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", color: "#26017b" }}>
                    Fuzz
                  </span>
                  <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                    Ads
                  </span>{" "}
                  reserves the right to showcase completed projects in its
                  portfolio.
                </li>
              </ul>
            </p>
            <li className="fw-bold">Termination of Services</li>
            <p>
              Either party may terminate this Agreement with written notice. In
              case of termination, all unpaid dues must be cleared by the
              client.
            </p>
            <li className="fw-bold">Liability</li>
            <p>
              <ul>
                <li>
                  <span style={{ fontWeight: "bold", color: "#26017b" }}>
                    Fuzz
                  </span>
                  <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                    Ads
                  </span>{" "}
                  will not be held liable for delays caused by third-party
                  services (e.g., hosting providers, payment gateways).
                </li>
                <li>
                  <span style={{ fontWeight: "bold", color: "#26017b" }}>
                    Fuzz
                  </span>
                  <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                    Ads
                  </span>{" "}
                  will not be responsible for indirect, incidental, or
                  consequential damages, including but not limited to lost
                  profits or data.
                </li>
              </ul>
            </p>
            <li className="fw-bold">Changes to Terms</li>
            <p>
              <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
              <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                Ads
              </span>{" "}
              reserves the right to modify these terms at any time. Clients will
              be notified of significant changes, and continued use of our
              services will indicate acceptance of the new terms.
            </p>
            <li className="fw-bold">Governing Law</li>
            <p>
              This Agreement is governed by and construed in accordance with the
              laws of India. Any disputes arising from these terms shall be
              resolved in the courts of Noida, U.P. - Jurisdiction.
            </p>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
