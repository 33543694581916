import React from 'react'
import MobileForm from '../components/mobileForm'

const MobilePage = () => {
  return (
    <>
    <div class="content-container">
        <section class="hero">
          <MobileForm />
        </section>
      </div>
    </>
  )
}

export default MobilePage
