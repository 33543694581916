// src/analytics.js
import ReactGA from "react-ga4";

export const initializeAnalytics = () => {
  ReactGA.initialize("G-W00QY2F8HY"); // Replace with your GA4 Measurement ID
};

export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};
