import React from "react";
import Navbar from "../components/Navbar";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import "../styles/main.css";
import WhatsAppIcon from "../components/WhatsappIcon";

const ContactUs = () => {
  return (
    <>
      <header>
        <Navbar />
      </header>

      <div class="content-container">
        <section class="contact">
          <Contact />
        </section>
      </div>
      <footer class="footer">
        <Footer />
      </footer>

      <WhatsAppIcon />
    </>
  );
};

export default ContactUs;
