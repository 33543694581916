import React from 'react'
import ResetPassword from '../components/ResetPassword'
import '../styles/main.css'

const ResetPasswordPage = () => {
  return (
    <>
    <div class="content-container" style={{backgroundColor: "#aa98d1"}}>
        <section class="login" style={{backgroundColor: "#aa98d1"}}>
          <ResetPassword />
        </section>
      </div>
    </>
  )
}

export default ResetPasswordPage