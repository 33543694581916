import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/main.css";
import WhatsAppIcon from "../components/WhatsappIcon";
import Services from "../components/ServicesComponent";

const ServicePage = () => {
  return (
    <>
      <header>
        <Navbar />
      </header>

      <div class="content-container">
        <section class="hero">
          <Services />
        </section>
      </div>

      <footer class="footer">
        <Footer />
      </footer>
      <WhatsAppIcon />
    </>
  );
};

export default ServicePage;
