import React, { useEffect, useState } from "react";
import "../styles/chooseplan.css";
import axios from "axios";
import cart from "../assets/icons/cart.png";
import paymentPartner from "../assets/icons/payment-partner-phonepe.png";
import loader from "../assets/fuzzads-loader.gif";

const plansData = {
  silver: {
    durations: {
      "1 Month": {
        salePrice: "Rs. 14,999/-",
        finalPrice: "Rs. 9,999/-",
        renewPrice: "Rs. 2,999/-",
        discount: 33.34,
      },
      "1 Year": {
        salePrice: "Rs. 24,999/-",
        finalPrice: "Rs. 19,999/-",
        renewPrice: "Rs. 14,999/-",
        discount: 20,
      },
      "2 Years": {
        salePrice: "Rs. 39,999/-",
        finalPrice: "Rs. 29,999/-",
        discount: 25,
        renewPrice: "Rs. 24,999/-",
      },
    },
  },
  gold: {
    durations: {
      "1 Month": {
        salePrice: "Rs. 24,999/-",
        finalPrice: "Rs. 14,999/-",
        renewPrice: "Rs. 4,999/-",
        discount: 40,
      },
      "1 Year": {
        salePrice: "Rs. 49,999/-",
        finalPrice: "Rs. 34,999/-",
        renewPrice: "Rs. 24,999/-",
        discount: 30,
      },
      "2 Years": {
        salePrice: "Rs. 49,999/-",
        finalPrice: "Rs. 59,999/-",
        renewPrice: "Rs. 49,999/-",
        discount: 20,
      },
    },
  },
  platinum: {
    durations: {
      "1 Month": {
        salePrice: "Rs. 39,999/-",
        finalPrice: "Rs. 29,999/-",
        renewPrice: "Rs. 9,999/-",
        discount: 25,
      },
      "1 Year": {
        salePrice: "Rs. 79,999/-",
        finalPrice: "Rs. 64,999/-",
        renewPrice: "Rs. 44,999/-",
        discount: 19,
      },
      "2 Years": {
        salePrice: "Rs. 1,24,999/-",
        finalPrice: "Rs. 99,999/-",
        renewPrice: "Rs. 79,999/-",
        discount: 20,
      },
    },
  },
};

const ChoosePlan = () => {
  const [selectedPlan, setSelectedPlan] = useState("gold");
  const [selectedDuration, setSelectedDuration] = useState("1 Month");
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [customerData, setCustomerData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    companyName: "",
    notes: "",
  });
  const [isFormValid, setIsFormValid] = useState(false); // Initially disabled
  const [errorMessage, setErrorMessage] = useState(""); // Message for the user
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Validate the form whenever customer data or terms change
    handleFormChange();
  }, [customerData, isTermsChecked]);

  const handleFormChange = () => {
    const isFormComplete =
      customerData.name &&
      customerData.email &&
      customerData.phone &&
      customerData.address &&
      customerData.city &&
      customerData.state &&
      customerData.pincode &&
      customerData.companyName &&
      customerData.notes;

    setIsFormValid(isFormComplete);
    setErrorMessage(
      isFormComplete ? "" : "Please fill in all details before proceeding."
    );
  };

  const handlePlanChange = (e) => {
    setSelectedPlan(e.target.value);
    setSelectedDuration("1 Month"); // Reset duration when plan changes
  };

  const handleDurationChange = (e) => {
    setSelectedDuration(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const currentPlanData = plansData[selectedPlan].durations[selectedDuration];

  const parsePrice = (priceString) => {
    const cleanedString = priceString.replace(/[^0-9]/g, "");
    return Number(cleanedString);
  };

  const finalPriceNum = parsePrice(currentPlanData.finalPrice);

  const handleCheckout = async () => {
    setErrorMessage("");
  
    // Validation for required fields
    if (
      !customerData.name ||
      !customerData.phone ||
      !customerData.email ||
      !customerData.address ||
      !customerData.state ||
      !customerData.city ||
      !customerData.pincode
    ) {
      setErrorMessage("Please fill in all required details before proceeding.");
      return;
    }
  
    if (!isTermsChecked) {
      setErrorMessage("Please agree to the terms and conditions before proceeding.");
      return;
    }
  
    setIsLoading(true);
  
    try {
      const redirectUrl = "https://fuzzads.com/thank-you";
      const callbackUrl = "https://fuzzads-main-server.onrender.com/phonepe/callback";
  
      const { data } = await axios.post(
        "https://fuzzads-main-server.onrender.com/api/phonepe/payment",
        {
          transactionId: `txn-${Date.now()}`,
          amount: finalPriceNum,
          redirectUrl,
          callbackUrl,
          mobileNumber: customerData.phone, // Optional but included
        }
      );
  
      if (data.paymentUrl) {
        window.location.href = data.paymentUrl;
      } else {
        setIsLoading(false);
        setErrorMessage("Failed to initiate payment. Please try again.");
      }
    } catch (error) {
      console.error("Error in payment:", error);
      setIsLoading(false);
  
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.error || "There was an error with the payment. Please try again.");
      } else {
        setErrorMessage("There was an error with the payment. Please try again.");
      }
    }
  };
  

  

  const salePriceNum = parsePrice(currentPlanData.salePrice);
  const priceDifference = salePriceNum - finalPriceNum;

  return (
    <>
      {isLoading && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1050,
          }}
        >
          <img src={loader} alt="Loading..." style={{ width: "150px" }} />
        </div>
      )}
      <div className="mb-5 mt-5 choose-plan container-fluid d-flex justify-content-center align-items-center min-vh-100">
        <div
          className="chooseplan-box p-4 rounded-3 bg-white"
          style={{
            minWidth: "300px",
            maxWidth: "1200px",
            width: "100%",
          }}
        >
          <div className="chooseplan-content row mt-3">
            {/* Left Side */}
            <div className="chooseplan-left col-12 col-md-6 p-3 mt-3 mt-md-0">
              <h4 className="mb-4 fw-bold" style={{ color: "#26017b" }}>
                BILLING <span style={{ color: "#00a5e0" }}>DETAILS</span>
              </h4>

              <form>
                {/* Name */}
                <div className="mb-3">
                  <label
                    htmlFor="name"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={customerData.name}
                    onChange={handleInputChange}
                    required
                    placeholder="Enter Full Name*"
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "10px",
                    }}
                  />
                </div>

                {/* Company Name (Optional) */}
                <div className="mb-3">
                  <label
                    htmlFor="company-name"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Company Name (Optional)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="company-name"
                    name="companyName"
                    value={customerData.companyName}
                    onChange={handleInputChange}
                    placeholder="Enter Company/Business Name"
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "10px",
                    }}
                  />
                </div>

                {/* Phone Number */}
                <div className="mb-3">
                  <label
                    htmlFor="phone"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={customerData.phone}
                    onChange={handleInputChange}
                    required
                    placeholder="Enter Phone Number*"
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "10px",
                    }}
                  />
                </div>

                {/* Email */}
                <div className="mb-3">
                  <label
                    htmlFor="email"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={customerData.email}
                    onChange={handleInputChange}
                    required
                    placeholder="Enter Email ID*"
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "10px",
                    }}
                  />
                </div>

                {/* Address */}
                <div className="mb-3">
                  <label
                    htmlFor="address"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    value={customerData.address}
                    onChange={handleInputChange}
                    required
                    placeholder="Enter your Address*"
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "10px",
                    }}
                  />
                </div>

                {/* State */}
                <div className="mb-3">
                  <label
                    htmlFor="state"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    State
                  </label>
                  <select
                    className="form-select"
                    id="state"
                    name="state"
                    value={customerData.state}
                    onChange={handleInputChange}
                    required
                    style={{
                      color: "#686869FF",
                      border: "2px solid #26017b",
                      borderRadius: "10px",
                    }}
                  >
                    <option value="">Select your State*</option>
                    <option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar Islands
                    </option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra and Nagar Haveli and Daman and Diu">
                      Dadra and Nagar Haveli and Daman and Diu
                    </option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>

                    {/* Add other states here */}
                  </select>
                </div>

                {/* City */}
                <div className="mb-3">
                  <label
                    htmlFor="city"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={customerData.city}
                    onChange={handleInputChange}
                    required
                    placeholder="Enter your City*"
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "10px",
                    }}
                  />
                </div>

                {/* Pincode */}
                <div className="mb-3">
                  <label
                    htmlFor="pincode"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pincode"
                    name="pincode"
                    value={customerData.pincode}
                    onChange={handleInputChange}
                    placeholder="Enter your Pincode*"
                    required
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "10px",
                    }}
                  />
                </div>

                {/* Other Notes (Optional) */}
                <div className="mb-3">
                  <label
                    htmlFor="notes"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Other Notes (Optional)
                  </label>
                  <textarea
                    className="form-control"
                    id="notes"
                    name="notes"
                    value={customerData.notes}
                    onChange={handleInputChange}
                    rows="3"
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "10px",
                    }}
                  ></textarea>
                </div>
              </form>
            </div>

            {/* Right Side */}
            <div
              className="chooseplan-right col-12 col-md-6 p-3 mt-3 mt-md-0"
              style={{
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                maxWidth: "550px",
              }}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={cart}
                  alt="cart"
                  className="me-2"
                  style={{ width: "40px" }}
                />
                <h3 className="fw-bold fs-2" style={{ color: "#26017b" }}>
                  Your <span style={{ color: "#00a5e0" }}>Cart</span>
                </h3>
              </div>

              <div
                className="p-3 shadow"
                style={{ backgroundColor: "#f6f6f6" }}
              >
                <div
                  className="chooseplan-left p-2 mt-3 mt-md-0"
                  style={{
                    minWidth: "100%",
                    maxWidth: "480px",
                    margin: "0 auto",
                  }}
                >
                  <div className="plan-name mb-3">
                    <select
                      id="plan-select"
                      value={selectedPlan}
                      onChange={handlePlanChange}
                      className="form-select fw-bold fs-4"
                      style={{
                        color: "#26017b",
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                      }}
                    >
                      <option value="silver">Silver Streak</option>
                      <option value="gold">Golden Glow</option>
                      <option value="platinum">Platinum Power</option>
                    </select>
                  </div>

                  <div
                    className="border-top mb-3"
                    style={{ color: "rgba(0, 0, 0, 0.25)" }}
                  ></div>

                  <div className="fullplan-detail d-flex flex-column flex-md-row justify-content-between align-items-center gap-3">
                    {/* Plan Left Details */}
                    <div className="plan-details w-100 me-md-3">
                      <label
                        htmlFor="duration-select"
                        className="mb-2 fw-bold"
                        style={{ color: "rgba(0, 0, 0, 0.5)" }}
                      >
                        Plan Period
                      </label>
                      <div
                        className="mb-2 fw-bold"
                        style={{ maxWidth: "200px" }}
                      >
                        {" "}
                        {/* Added container for width control */}
                        <select
                          id="duration-select"
                          value={selectedDuration}
                          onChange={handleDurationChange}
                          className="form-select"
                        >
                          <option value="1 Month">1 Month</option>
                          <option value="1 Year">1 Year</option>
                          <option value="2 Years">2 Years</option>
                        </select>
                      </div>
                      <p
                        className="mt-2 fw-bold"
                        style={{ color: "rgba(0, 0, 0, 0.5)" }}
                      >
                        Renew at {currentPlanData.renewPrice}
                      </p>
                    </div>

                    {/* Discount Container */}
                    <div
                      className="text-md-end text-center mt-3 mt-md-0"
                      style={{ display: "inline-block" }}
                    >
                      <h4
                        className="text-white py-2 px-2 rounded-pill mb-1"
                        style={{
                          backgroundColor: "rgba(0, 184, 95, 0.88)",
                          fontSize: "15px",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {currentPlanData.discount}% off - Rs.{" "}
                        {priceDifference.toLocaleString()}
                      </h4>
                      <h5 className="fw-bold mb-0" style={{ color: "#26017b" }}>
                        Discount !!!
                      </h5>
                    </div>
                  </div>
                </div>

                {/* Subtotal */}
                <div
                  className="subtotal fs-2 fw-bold mt-5"
                  style={{ color: "#26017b" }}
                >
                  Subtotal
                </div>

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <p className="fw-bold mb-0" style={{ color: "#26017b" }}>
                    Final Price
                  </p>
                  <p className="fw-bold mb-0" style={{ color: "#26017b" }}>
                    ~ {currentPlanData.salePrice}
                  </p>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-2 mb-2">
                  <p className="fw-bold mb-0" style={{ color: "#26017b" }}>
                    Discount - {currentPlanData.discount}%
                  </p>
                  <p className="fw-bold mb-0" style={{ color: "#00a5e0" }}>
                    - Rs. {priceDifference.toLocaleString()}/-
                  </p>
                </div>

                {/* Line Divider */}
                <div
                  className="mb-2"
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "rgba(0, 0, 0, 1)",
                    margin: "0 auto",
                  }}
                ></div>

                <div className="d-flex justify-content-between align-items-center mt-0 mb-5">
                  <p className="fw-bold mb-0" style={{ color: "#26017b" }}>
                    Sale Price
                  </p>
                  <p
                    className="fw-bold mb-0 fs-5"
                    style={{ color: "rgba(0, 184, 95, 0.88)" }}
                  >
                    {currentPlanData.finalPrice}
                  </p>
                </div>

                <div className="d-flex justify-content-start mb-3">
                  <img
                    src={paymentPartner}
                    alt="fuzzads"
                    className="img-fluid"
                    style={{
                      width: "100%",
                      maxWidth: "250px",
                      marginTop: "4rem",
                    }}
                  />
                </div>

                <div className="d-flex align-items-center mt-3 mb-3">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={isTermsChecked}
                    onChange={() => setIsTermsChecked(!isTermsChecked)}
                    className="form-check-input me-2 mb-1"
                  />
                  <label htmlFor="terms" className="form-check-label">
                    <a
                      href="/terms-and-conditions"
                      className="fw-bold text-decoration-none"
                    >
                      <span style={{ color: "#26017b" }}>
                        I have read and agreed to the service
                      </span>{" "}
                      <span style={{ color: "#00a5e0" }}>
                        Terms & Conditions*
                      </span>
                    </a>
                  </label>
                </div>

                <div
                  className="text-center py-2 mt-2 text-black fw-bold"
                  style={{ backgroundColor: "rgba(38, 1, 123, 0.2)" }}
                >
                  {selectedDuration === "1 Month"
                    ? "Try 12 months and get free 1 year hosting and domain free"
                    : "WOW! you get a FREE Domain name for 1 year."}
                </div>
                <div className="checkout-content d-flex flex-column flex-md-row justify-content-between align-items-center mt-3">
                  <p
                    className="small fw-bold mb-2 text-center me-md-3"
                    style={{ color: "#26017b" }}
                  >
                    &gt; 24 hrs money-back guarantee*
                  </p>

                  <button
                    className="btn fw-bold px-5"
                    style={{
                      backgroundColor: "#26017b",
                      color: "white",
                      border: "2px solid #26017b",
                    }}
                    onClick={handleCheckout}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "white";
                      e.currentTarget.style.color = "#26017b";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "#26017b";
                      e.currentTarget.style.color = "white";
                    }}
                  >
                    {isLoading ? "Processing..." : "Check Out"}
                  </button>
                </div>
              </div>
            </div>
            {errorMessage && (
              <div
                style={{
                  color: "red",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChoosePlan;
