import React, { useState } from "react";
import "../styles/WhatsAppIcon.css";
import WhatsappIcon from "../assets/whatsapp.png";

const WhatsAppIcon = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    window.open("https://wa.me/+919310930177", "_blank");
  };

  return (
    <div
      className={`whatsapp-container ${isHovered ? "expanded" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && <div className="whatsapp-text">+919310930177</div>}
      <div className="whatsapp-icon" onClick={handleClick}>
        <img src={WhatsappIcon} alt="WhatsApp" className="whatsapp-img" />
      </div>
    </div>
  );
};

export default WhatsAppIcon;
