import React, { useState, useEffect, useRef } from "react";
import loader from "../assets/fuzzads-loader.gif";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import ResetBro from "../assets/reset-password.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../styles/resetpassword.css";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpCountdown, setOtpCountdown] = useState(10 * 60); // OTP valid for 5 minutes
  const [resendCountdown, setResendCountdown] = useState(60); // Resend OTP in 1 minute
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const inputRefs = useRef([]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  // Function to handle the focus shift
  const handleChange = (e, index) => {
    const value = e.target.value;
    // Update the otp state with each entered digit
    const newOtp = otp.split("");
    newOtp[index] = value;
    setOtp(newOtp.join("")); // Update the OTP state as a full string

    // If the current input is filled and is not the last input, focus the next input
    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  useEffect(() => {
    if (otpCountdown > 0) {
      const otpTimer = setInterval(
        () => setOtpCountdown(otpCountdown - 1),
        1000
      );
      return () => clearInterval(otpTimer);
    }
  }, [otpCountdown]);

  useEffect(() => {
    if (resendCountdown > 0) {
      const resendTimer = setInterval(
        () => setResendCountdown(resendCountdown - 1),
        1000
      );
      return () => clearInterval(resendTimer);
    }
  }, [resendCountdown]);

  const handleSendOtp = async () => {
    setIsLoading(true); // Show the loader when OTP request starts

    try {
      const response = await fetch(
        "https://fuzzads-main-server.onrender.com/api/auth/request-reset",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();
      if (response.status === 200) {
        setShowOtpModal(true); // Show OTP Modal
      } else {
        alert(data.msg); // Show error message if user doesn't exist
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("There was an error sending the OTP.");
    } finally {
      setIsLoading(false); // Hide the loader when OTP request finishes
    }
  };

  const handleValidateOtp = async () => {
    try {
      const response = await axios.post(
        "https://fuzzads-main-server.onrender.com/api/auth/verify-otp",
        { email, otp }
      );
      if (response.status === 200) {
        setShowOtpModal(false); // Close the OTP modal
        setIsOtpVerified(true); // Enable password fields and submit button
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert(error.response ? error.response.data.msg : "Something went wrong!");
    }
  };

  // Handle form submission to reset password
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload on form submission
  
    // Validate passwords match
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
  
    setIsLoading(true); // Show loading spinner on submit
  
    try {
      const response = await fetch(
        "https://fuzzads-main-server.onrender.com/api/auth/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, newPassword }),
        }
      );
  
      const data = await response.json();
      if (response.status === 200) {
        alert(data.msg); // Success message
        // Redirect user to the login page after a successful reset
        navigate("/login"); // Use navigate from react-router-dom
      } else {
        alert(data.msg); // Show error if password reset fails
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("There was an error resetting the password.");
    } finally {
      setIsLoading(false); // Hide the loading spinner
    }
  };

  return (
    <>
      <div className="fullscreen-container">
        {loading && (
          <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50">
            <img
              src={loader}
              alt="Loading..."
              style={{ width: "250px", height: "250px" }}
            />
          </div>
        )}

        <div className="container d-flex justify-content-center align-items-center vh-100">
          <div
            className="row w-100 justify-content-center"
            style={{ borderRadius: "20px", overflow: "hidden" }}
          >
            {/*Left*/}
            <div
              className="col-12 col-md-5 p-3"
              style={{
                backgroundColor: "#26017b",
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
              }}
            >
              <h2
                className="login-heading text-center fw-bold fs-1"
                style={{ color: "white" }}
              >
                RESET PASSWORD
              </h2>
              <p
                className="login-subheading text-center fw-bold fs-4"
                style={{ color: "white" }}
              >
                Create a Strong Password
              </p>

              <form className="login-form"  onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label
                    htmlFor="username"
                    className="form-label fw-bold"
                    style={{ color: "white" }}
                  >
                    Registered Email Address
                  </label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    className="form-control"
                    placeholder="Enter Your Registered Email"
                    required
                    value={email}
                    onChange={handleEmailChange}
                    style={{ borderRadius: "20px", backgroundColor: "white" }}
                  />
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      type="button"
                      className="btn fw-bold"
                      style={{
                        backgroundColor: "#00a5e0",
                        color: "white",
                        borderRadius: "20px",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "white";
                        e.currentTarget.style.color = "#00a5e0";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "#00a5e0";
                        e.currentTarget.style.color = "white";
                      }}
                      onClick={handleSendOtp} // Show OTP Modal
                      disabled={isLoading} // Disable button while loading
                    >
                      {isLoading ? (
                        <div className="spinner"></div> // Show loader if loading
                      ) : (
                        "Send OTP"
                      )}
                    </button>
                  </div>
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="password"
                    className="form-label fw-bold"
                    style={{ color: "white" }}
                  >
                    Enter New Password
                  </label>
                  <div className="input-group">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      name="password"
                      className="form-control"
                      placeholder="Enter your Password"
                      required
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      disabled={!isOtpVerified}
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "white",
                        cursor: isOtpVerified ? "auto" : "not-allowed",
                      }}
                    />
                    <button
                      type="button"
                      className="input-group-text"
                      onClick={togglePasswordVisibility}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        border: "none",
                        color: "white",
                      }}
                    >
                      {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="confirmPassword"
                    className="form-label fw-bold"
                    style={{ color: "white" }}
                  >
                    Confirm New Password
                  </label>
                  <div className="input-group">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="confirmPassword"
                      name="confirmPassword"
                      className="form-control"
                      placeholder="Confirm your Password"
                      required
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      disabled={!isOtpVerified}
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "white",
                        cursor: isOtpVerified ? "auto" : "not-allowed",
                      }}
                    />
                    <button
                      type="button"
                      className="input-group-text"
                      onClick={togglePasswordVisibility}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        border: "none",
                        color: "white",
                      }}
                    >
                      {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn w-100 fw-bold fs-5"
                  style={{
                    backgroundColor: "#00a5e0",
                    color: "white",
                    borderRadius: "20px",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "white";
                    e.currentTarget.style.color = "#00a5e0";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "#00a5e0";
                    e.currentTarget.style.color = "white";
                  }}
                  disabled={!isOtpVerified || isLoading}
                >
                  {isLoading ? (
                    <div className="spinner"></div> // Show loading spinner on submit
                  ) : (
                    "SUBMIT"
                  )}
                </button>

                <p
                  className="signup-text text-left mt-3"
                  style={{ fontWeight: "bold", color: "white" }}
                >
                  Back to Login? &nbsp;
                  <span>
                   <Link
                    className="signup-link"
                    to="/login"
                    style={{ textDecoration: "none", color: "#00a5e0" }}
                  >
                      Login Now
                  </Link>
                  </span>
                </p>
              </form>
            </div>
            {/*Right*/}
            <div
              className="col-12 col-md-5 d-none d-md-flex justify-content-center align-items-center bg-light"
              style={{
                borderBottomRightRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            >
              <div className="login-content text-center">
                <h2
                  className="company fw-bold mb-5"
                  style={{ fontSize: "6rem" }}
                >
                  <span style={{ color: "#26017b" }}>Fuzz</span>
                  <span style={{ color: "#00a5e0" }}>Ads</span>
                </h2>
                <img
                  src={ResetBro}
                  alt="FuzzAds"
                  className="login-image align-items-end mt-5"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* OTP Modal */}
      {showOtpModal && (
        <div
          className="modal show"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          onClick={() => setShowOtpModal(false)}
        >
          <div
            className="modal-dialog"
            style={{
              maxWidth: "400px",
              margin: "10% auto",
              borderRadius: "20px",
              backgroundColor: "#00a5e0",
              padding: "20px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="modal-content"
              style={{ backgroundColor: "#00a5e0", border: "none" }}
            >
              <div
                className=""
                style={{
                  borderBottom: "none",
                  marginTop: "0",
                  marginBottom: "0",
                }}
              >
                <h5
                  className="modal-title fw-bold fs-4"
                  style={{ color: "white", textAlign: "center", width: "100%" }}
                >
                  VALIDATE OTP
                </h5>
              </div>

              <div
                className="text-center fw-bold mt-0"
                style={{ color: "white" }}
              >
                <p>Check Your Registered Email Inbox</p>
              </div>

              <div className="modal-body">
                <p className="text-left fw-bold" style={{ color: "white" }}>
                  Enter OTP
                </p>
                <div className="mb-3 d-flex justify-content-center">
                  {[...Array(6)].map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      className="otp-input"
                      ref={(el) => (inputRefs.current[index] = el)}
                      onChange={(e) => handleChange(e, index)}
                      style={{
                        width: "40px",
                        height: "40px",
                        margin: "0 5px",
                        textAlign: "center",
                        fontSize: "20px",
                        borderRadius: "5px",
                        border: "2px solid white",
                        color: "#000",
                        padding: "0",
                        zIndex: "10",
                      }}
                    />
                  ))}
                </div>

                <div className="d-flex flex-column">
                  <span className="fw-bold mb-4" style={{ color: "#26017b" }}>
                    OTP is valid for{" "}
                    {`${Math.floor(otpCountdown / 60)
                      .toString()
                      .padStart(2, "0")}:${(otpCountdown % 60)
                      .toString()
                      .padStart(2, "0")}`}
                  </span>
                </div>
              </div>

              <div className="modal-footer" style={{ borderTop: "none" }}>
                <button
                  className="btn display-flex justify-content-center align-items-center fw-bold"
                  style={{
                    backgroundColor: "#26017B",
                    color: "white",
                    borderRadius: "20px",
                  }}
                  onClick={handleValidateOtp} // Call validation function
                >
                  VALIDATE
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
