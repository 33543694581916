import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/main.css";
import WhatsAppIcon from "../components/WhatsappIcon";
import ChoosePlan from "../components/ChoosePlan";
import "bootstrap/dist/css/bootstrap.min.css";

const ChoosePlanPage = () => {
  return (
    <>
    <header>
        <Navbar />
      </header>

      <div class="content-container">
        <section class="plan " style={{marginTop: '6rem'}} >
          <ChoosePlan />
        </section>
      </div>

      <footer class="footer">
      </footer>
      <WhatsAppIcon />
    </>
  );
};

export default ChoosePlanPage;
