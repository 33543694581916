import React from "react";
import "../styles/main.css";
import Loginpage from "../components/LoginComponent";

const LoginMainPage = () => {
  return (
    <>
      <div class="content-container" style={{backgroundColor: "#aa98d1"}}>
        <section class="login" style={{backgroundColor: "#aa98d1"}}>
          <Loginpage />
        </section>
      </div>
    </>
  );
};

export default LoginMainPage;
