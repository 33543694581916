import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "../styles/freedemoStyles.css";
import catalogue from "../assets/free-demo.png";
import loader from "../assets/fuzzads-loader.gif";
import { motion } from "framer-motion";
import Banner from "./Banner";

const Freedemo = forwardRef((props, ref) => {
  const leftToRightVariant = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const rightToLeftVariant = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const [phone, setPhone] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      phone: phone,
    });
  };

  const handleDateTimeChange = (date) => {
    setFormData({
      ...formData,
      dateTime: date,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        "https://fuzzads-main-server.onrender.com/api/demoform",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            location: formData.companyName,
            timeslot: formData.dateTime.toString(),
          }),
        }
      );

      if (response.ok) {
        setSuccessMessage("We will connect you soon!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          companyName: "",
          dateTime: new Date(),
        });
      } else {
        setSuccessMessage("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setSuccessMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
      setTimeout(() => setSuccessMessage(""), 3000);
    }
  };

  return (
    <>
      <section ref={ref} className="container my-5 mb-0">
        <div className="row g-4 align-items-center">
          {/* Left Section */}
          <motion.div
            className="col-lg-6 col-md-6"
            initial="hidden"
            whileInView="visible"
            variants={leftToRightVariant}
            viewport={{ once: true }}
          >
            <div className="text-center mb-0">
              <h2 className="display-5 fw-bold" style={{ color: "#26017b" }}>
                Discover What We Offer
              </h2>
              <h3 className="fw-bold" style={{ color: "#00a5e0" }}>
                Schedule Your Free Demo
              </h3>
            </div>
            <img
              className="img-fluid rounded"
              src={catalogue}
              alt="Catalogue"
            />
          </motion.div>

          {/* Right Section */}
          <motion.div
            className="col-lg-6 col-md-6"
            initial="hidden"
            whileInView="visible"
            variants={rightToLeftVariant}
            viewport={{ once: true }}
          >
            {isLoading && (
              <div
                className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1050,
                }}
              >
                <img src={loader} alt="Loading..." style={{ width: "150px" }} />
              </div>
            )}

            <form onSubmit={handleSubmit}>
              {/* Full Name and Mobile Number in one row */}
              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <label
                    htmlFor="name"
                    className="form-label fw-bold fs-5"
                    style={{ color: "#26017b" }}
                  >
                    Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Enter Your Name*"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    style={{
                      border: "2px solid #00a5e0",
                      borderRadius: "100px",
                      width: "100%",
                    }} // Ensure full width
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label
                    htmlFor="phone"
                    className="form-label fw-bold fs-5"
                    style={{ color: "#26017b" }}
                  >
                    Mobile Number*
                  </label>
                  <div className="d-flex align-items-center">
                    <div className="me-2" style={{ width: "100%" }}>
                      {" "}
                      {/* Ensure full width */}
                      {/* Wrapping the PhoneInput for better layout */}
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={phone}
                        onChange={setPhone}
                        defaultCountry="IN"
                        id="phone"
                        name="phone"
                        className="form-control phone-input-custom"
                        required
                        style={{
                          width: "100%", // Ensure full width
                          border: "2px solid #00a5e0",
                          borderRadius: "100px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Email - Full width */}
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="form-label fw-bold fs-5"
                  style={{ color: "#26017b" }}
                >
                  Email*
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Enter Your Email ID"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  style={{
                    border: "2px solid #00a5e0",
                    borderRadius: "100px",
                    width: "100%",
                  }} // Ensure full width
                />
              </div>

              {/* Company Name and Date/Time in one row */}
              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <label
                    htmlFor="companyName"
                    className="form-label fw-bold fs-5"
                    style={{ color: "#26017b" }}
                  >
                    Company Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    name="companyName"
                    placeholder="Your Company Name"
                    value={formData.companyName}
                    onChange={handleInputChange}
                    style={{
                      border: "2px solid #00a5e0",
                      borderRadius: "100px",
                      width: "100%",
                    }} // Ensure full width
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label
                    htmlFor="dateTime"
                    className="form-label fw-bold fs-5"
                    style={{ color: "#26017b" }}
                  >
                    Date/Time Slot*
                  </label>
                  <div
                    style={{
                      width: "100%",
                      border: "2px solid #00a5e0",
                      borderRadius: "100px",
                    }}
                  >
                    <DatePicker
                      selected={formData.dateTime}
                      onChange={handleDateTimeChange}
                      showTimeSelect
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      minTime={new Date(new Date().setHours(10, 0))}
                      maxTime={new Date(new Date().setHours(22, 0))}
                      required
                      className="form-control custom-date-picker"
                    />
                  </div>
                </div>
              </div>

              {/* Submit button */}
              <button
                type="submit"
                className="btn w-100 fw-bold"
                style={{
                  backgroundColor: "#26017b",
                  color: "#fff",
                  borderRadius: "100px",
                  border: "2px solid #26017b",
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#26017b";
                  e.currentTarget.style.color = "white";
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "white";
                  e.currentTarget.style.color = "#26017b";
                }}
              >
                Ready to be Connected on Meet
              </button>
            </form>

            {/* Success Message */}
            {successMessage && (
              <div
                className="alert alert-success mt-3"
                style={{ backgroundColor: "#26017b", color: "#fff" }}
                role="alert"
              >
                {successMessage}
              </div>
            )}
          </motion.div>
        </div>
      </section>
      <Banner />
    </>
  );
});

export default Freedemo;
