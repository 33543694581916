import React from "react";
import { Link } from "react-router-dom";
import errorgif from "../assets/icons/error.gif";
import { useNavigate } from "react-router-dom";

const Error = () => {
  const navigate = useNavigate();
  return (
    <div
      className="d-flex justify-content-center align-items-center min-vh-100 thank-container"
      style={{ fontFamily: "Lato, sans-serif" }}
    >
      <div
        className="text-center p-4 m-3 rounded-3"
        style={{ maxWidth: "800px" }}
      >
        <img
          src={errorgif}
          alt="error"
          className="img-fluid mb-4"
          style={{ maxWidth: "400px" }}
        />
        <h1 className="fw-bold fs-1" style={{ color: "#26017b" }}>
          Looks Like You're Lost
        </h1>
        <h4 className="fs-4" style={{ color: "#00a5e0" }}>
          The page you are looking for is not available!
        </h4>
        <button
          onClick={() => navigate("/")}
          className="btn  mt-4 fs-5 fw-bold"
          style={{
            color: "#00a5e0",
            border: "2px solid #00a5e0",
            borderRadius: "50px",
            transition: "0.3s ease",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#00a5e0";
            e.currentTarget.style.color = "white";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "white";
            e.currentTarget.style.color = "#00a5e0";
          }}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default Error;
