import React from "react";
import "../styles/refund.css";

const ReturnRefund = () => {
  return (
    <div className="refund-main container-fluid min-vh-100 bg-light">
      <div className="row justify-content-center py-5 mt-5">
        <div
          className="col-lg-10 col-md-10 col-sm-12 bg-white p-4 shadow"
          style={{ borderRadius: "20px" }}
        >
          <h1 className="fw-bold fs-2 text-center mb-4 mt-4">
            Return and Refund Policy
          </h1>
          <p className="mb-4">
            At{" "}
            <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
            <span style={{ fontWeight: "bold", color: "#00a5e0" }}>Ads</span> –
            , we are committed to providing top-quality digital marketing and
            web development services. We value your satisfaction, and our return
            and refund policy is designed to ensure clarity and transparency.
            Please read the following policy carefully before making a purchase.
          </p>

          <p>
            <ol>
              <li className="fw-bold fs-5 mb-2">General Refund Policy</li>
              <ul>
                <li>
                  Refunds are applicable only within 24 hours of plan purchase.
                  Beyond this timeframe, no refunds will be issued, as our
                  services require substantial time and resource commitments.
                  Clients are responsible for covering any payment gateway fees
                  associated with the refund.
                </li>
                <li className="mb-3">
                  If you wish to request a refund within the 24-hour window,
                  please contact us immediately at <b>support@fuzzads.com</b>{" "}
                  with your order details and the reason for the refund.
                </li>
              </ul>
              <li className="fw-bold fs-5 mb-2">Eligible Refund Conditions</li>
              You are eligible for a refund under the following circumstances:
              <ul>
                <li>
                  You have purchased a{" "}
                  <span style={{ fontWeight: "bold", color: "#26017b" }}>
                    Fuzz
                  </span>
                  <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                    Ads
                  </span>{" "}
                  plan within the last 24 hours and wish to cancel it.
                </li>
                <li>
                  No work or services (such as graphic design, web development,
                  social media setup, etc.) have commenced on your project.
                </li>
                <li className="mb-3">
                  If any technical issue on our platform caused duplicate
                  charges or errors in the payment process.
                </li>
              </ul>
              <li className="fw-bold fs-5 mb-2">Non-Refundable Services</li>
              Refunds are not applicable in the following cases:
              <ul>
                <li>
                  <b> After the 24-hour window:</b> Once 24 hours have passed
                  since the purchase, no refund requests will be accepted.
                </li>
                <li>
                  <b>Work in Progress:</b> If work has already started on your
                  project (including logo creation, social media setup, web
                  development, etc.), you are no longer eligible for a refund,
                  even if the request is within 24 hours of the purchase
                </li>
                <li>
                  <b>Plan Renewals:</b> Renewals of any{" "}
                  <span style={{ fontWeight: "bold", color: "#26017b" }}>
                    Fuzz
                  </span>
                  <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                    Ads
                  </span>{" "}
                  service plan (Silver Streak, Golden Glow, Platinum Power) are
                  non-refundable.
                </li>
                <li className="mb-3">
                  <b>Third-Party Costs:</b> Costs associated with third-party
                  services, such as hosting, domain registration, payment
                  gateway integration (e.g., Razorpay), or any additional
                  purchases made on your behalf, are non-refundable.
                </li>
              </ul>
              <li className="fw-bold fs-5 mb-2">
                Process for Requesting a Refund
              </li>
              To request a refund:
              <ol>
                <li>
                  Email us at <b>support@fuzzads.com</b> within 24 hours of your
                  plan purchase.
                </li>
                <li>Include the following details:</li>
                <ul>
                  <li>Your name and contact information </li>
                  <li>Date of purchase</li>
                  <li>Plan purchased</li>
                  <li>Reason for refund request</li>
                </ul>
                <li>
                  Our team will review your request and respond within 2-3
                  business days.
                </li>
                <li className="mb-3">
                  If the refund is approved, the amount will be processed back
                  to your original payment method within 5-7 business days.
                </li>
              </ol>
              <li className="fw-bold fs-5 mb-2">
                Refunds for Canceled Projects
              </li>
              <p className="mb-3">
                In cases where{" "}
                <span style={{ fontWeight: "bold", color: "#26017b" }}>
                  Fuzz
                </span>
                <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                  Ads
                </span>{" "}
                initiates a project but you decide to cancel the service midway,
                no refund will be provided for work already completed or
                services already delivered.
              </p>
              <li className="fw-bold fs-5 mb-2">
                Performance-Based Incentives
              </li>
              <p className="mb-3">
                For services that include performance-based incentives or
                deliverables (e.g., social media marketing campaigns, ad spend
                management), refunds are not applicable once the campaign or
                project has been initiated.
              </p>
              <li className="fw-bold fs-5 mb-2">
                Changes or Modifications to Services
              </li>
              <p className="mb-3">
                If you request changes or modifications to a project after it
                has begun, and this leads to a delay in the original delivery
                timeline, refunds will not be issued.
              </p>
              <li className="fw-bold fs-5 mb-2">Disputes and Resolution</li>
              <p className="mb-3">
                If you encounter any issues with your purchase or our services,
                we encourage you to reach out to us first for resolution. We
                strive to address any concerns fairly and in a timely manner.
              </p>
            </ol>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReturnRefund;
