import React from "react";
import "../styles/privacy.css";

const Privacypolicy = () => {
  return (
    <div className="privacy-main container-fluid min-vh-100 bg-light">
      <div className="row justify-content-center py-5 mt-5">
        <div
          className="col-lg-10 col-md-10 col-sm-12 bg-white p-4 shadow"
          style={{ borderRadius: "20px" }}
        >
          <h1 className="fw-bold fs-2 text-center mb-4 mt-4">Privacy Policy</h1>
          <p>
            At{" "}
            <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
            <span style={{ fontWeight: "bold", color: "#00a5e0" }}>Ads</span> –
            we value your privacy and are committed to protecting your personal
            information. This Privacy Policy outlines how we collect, use, and
            safeguard your data when you interact with our services. Please read
            this policy carefully to understand our practices.
          </p>
          <ol>
            <li className="fw-bold mb-2">Infornation We Collect</li>

            <span className="fw-bold">Personal Information:</span>
            <p>
              When you sign up for our services or engage with our platform, we
              collect personal information such as:
              <ul>
                <li>Name</li>
                <li>Email Address</li>
                <li>Phone Number</li>
                <li>Billing Information (if applicable)</li>
                <li>Business Details (company name, industry, etc.)</li>
              </ul>
              This data is collected through forms, account creation, and
              communication channels to provide the services you request.
            </p>
            <span className="fw-bold">Non-Personal Information:</span>
            <p>
              We also collect non-personal data through cookies and similar
              technologies:
              <ul>
                <li>IP Address</li>
                <li>Browser Type</li>
                <li>Device Information</li>
                <li>Website usage (pages visited, time spent, etc.)</li>
              </ul>
              This information helps us improve user experience and monitor
              website performance.
            </p>

            <li className="fw-bold mb-2">How We Use Your Information</li>
            <span className="fw-bold">Your information is used to:</span>
            <p>
              <ul>
                <li>Provide and manage the services you purchase.</li>
                <li>
                  Communicate with you regarding your account, services, and
                  updates.
                </li>
                <li>Process payments and transactions.</li>
                <li>
                  Customize your experience on our platform and offer relevant
                  content.
                </li>
                <li>Analyze website usage and optimize our services.</li>
                <li>
                  Send marketing emails or promotional materials (only if you
                  opt-in).
                </li>
              </ul>
            </p>
            <li className="fw-bold">Data Collection via Authentication</li>
            <p>
              <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
              <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                Ads
              </span>{" "}
              collects and stores personal data when users authenticate through
              login and sign-ups on our platform. This data is stored securely
              and used solely for purposes related to your account management
              and service delivery.
            </p>
            <li className="fw-bold">Sharing Your Information</li>
            <p>
              <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
              <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                Ads
              </span>{" "}
              does not sell, trade, or rent your personal information to third
              parties. However, we may share data with trusted third-party
              service providers, such as:
              <ul>
                <li>
                  Payment processors (e.g., Razorpay) for handling transactions.
                </li>
                <li>
                  Hosting providers (e.g., Hostinger) for managing website and
                  domain services.
                </li>
                <li>
                  Marketing and analytics tools to help us improve our
                  offerings.
                </li>
              </ul>
              These third parties are obligated to keep your information secure
              and confidential.
            </p>
            <li className="fw-bold">Cookies and Tracking Technologies</li>
            <p>
              Our website uses cookies to enhance your experience by:
              <ul>
                <li>Remembering your preferences.</li>
                <li>Enabling secure sign-ins.</li>
                <li>Tracking website usage patterns.</li>
              </ul>
              You can control cookie settings via your browser. However,
              disabling cookies may affect the functionality of our website.
            </p>
            <li className="fw-bold">Data Security</li>
            <p>
              We prioritize the security of your personal information and
              implement various safeguards:
              <ul>
                <li>
                  Encryption for sensitive data such as payment information.
                </li>
                <li>
                  Secure storage systems to protect against unauthorized access.
                </li>
                <li>
                  Regular audits and monitoring of our systems for
                  vulnerabilities.
                </li>
              </ul>
              However, no method of transmission over the internet or electronic
              storage is entirely secure. While we strive to protect your data,
              we cannot guarantee absolute security.
            </p>
            <li className="fw-bold">Your Rights</li>
            <p>
              You have the right to:
              <ul>
                <li>Access and review your personal data.</li>
                <li>Request corrections or updates to your information.</li>
                <li>Opt out of receiving marketing communications.</li>
                <li>Delete your account and associated personal data.</li>
              </ul>
            </p>
            <li className="fw-bold">Third-Party Links</li>
            <p>
              Our website may contain links to third-party websites or services.
              We are not responsible for the privacy practices of these external
              sites. We encourage you to review their privacy policies before
              providing personal information.
            </p>
            <li className="fw-bold">Children's Privacy</li>
            <p>
              <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
              <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                Ads
              </span>{" "}
              services are not directed at individuals under the age of 18. We
              do not knowingly collect personal information from minors. If we
              become aware of such data collection, we will take steps to delete
              it.
            </p>
            <li className="fw-bold">Changes to This Privacy Policy</li>
            <p>
              <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
              <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                Ads
              </span>{" "}
              reserves the right to update or modify this Privacy Policy at any
              time. Any changes will be reflected on this page, and significant
              changes will be communicated via email or a website notification.
            </p>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
