import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { motion } from "framer-motion";
import plansData from "../data/plans.json";

const PricingCards = ({ scrollToFreeDemo }) => {
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  // Toggle function for showing/hiding the description
  const toggleDescription = () => {
    setIsDescriptionVisible(!isDescriptionVisible);
  };
  const settings = {
    dots: false,
    infinite: false, // Disable infinite scrolling on larger screens
    speed: 100, // Reduce speed for smoother scrolling
    slidesToShow: 3, // Show all three cards on larger screens
    slidesToScroll: 1,
    swipeToSlide: true, // Enables continuous swiping through slides
    touchThreshold: 10, // Lower threshold for faster swipe response
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Show one card at a time on mobile
          slidesToScroll: 1,
          infinite: true, // Enable infinite scrolling on mobile
          speed: 100, // Ensure the speed is set for mobile as well
          swipeToSlide: true,
          touchThreshold: 30,
        },
      },
    ],
  };

  const featuresData = [
    {
      heading: "Branding & Design",
      services: [
        {
          service: "Logo Design",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Visiting Card Design",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Prospectus Design",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Email Signature Design",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Brand Video Creation",
          plans: {
            silver: {
              available: true,
              description: "1 video/year ",
            },
            gold: {
              available: true,
              description: "1 video/year",
            },
            platinum: {
              available: true,
              description: "2 videos/year",
            },
          },
        },
        {
          service: "Social Media Posts",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Banners",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "Web Development",
      services: [
        {
          service: "Website Development",
          plans: {
            silver: {
              available: true,
              description:
                "Basic website design created with a website builder.",
            },
            gold: {
              available: true,
              description:
                "Advanced website design using WordPress and plugins.",
            },
            platinum: {
              available: true,
              description:
                "High-quality custom website developed using the MERN stack.",
            },
          },
        },
        {
          service: "Custom Business Emails",
          plans: {
            silver: {
              available: true,
              description: "10 emails",
            },
            gold: {
              available: true,
              description: "20 emails",
            },
            platinum: {
              available: true,
              description: "50 emails",
            },
          },
        },
        {
          service: "Payment Gateway Integration",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Custom Domain Setup",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Backups",
          plans: {
            silver: {
              available: true,
              description: "monthly backup",
            },
            gold: {
              available: true,
              description: "monthly backup",
            },
            platinum: {
              available: true,
              description: "daily backup",
            },
          },
        },
        {
          service: "SSL Certificate",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "User Dashboard",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "Digital Marketing",
      services: [
        {
          service: "Social Media Marketing",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Instagram Marketing",
          plans: {
            silver: {
              available: true,
              description: "1 post/week",
            },
            gold: {
              available: true,
              description: "1 post/week",
            },
            platinum: {
              available: true,
              description: "2 posts/week",
            },
          },
        },
        {
          service: "Facebook Marketing",
          plans: {
            silver: {
              available: true,
              description: "1 post/week",
            },
            gold: {
              available: true,
              description: "1 post/week",
            },
            platinum: {
              available: true,
              description: "2 posts/week",
            },
          },
        },
        {
          service: "Linkedin Marketing",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "1 post/week",
            },
            platinum: {
              available: true,
              description: "2 posts/week",
            },
          },
        },
        {
          service: "X/Twitter Marketing",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "1 post/week",
            },
            platinum: {
              available: true,
              description: "2 posts/week",
            },
          },
        },
        {
          service: "Whatsapp Broadcast",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "1 post/week",
            },
            platinum: {
              available: true,
              description: "2 post/week",
            },
          },
        },
        {
          service: "Email Marketing",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Google Ads",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Meta Ads",
          plans: {
            silver: {
              available: true,
              description: "optional",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "LinkedIn Ads",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "X/Twitter Ads",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "Social Media & Content Management",
      services: [
        {
          service: "Social Media Management",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Instagram Management",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Facebook Management",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "LinkedIn Management",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "YouTube Channel Management",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "YouTube Video Uploads",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "WhatsApp Management",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "App Development",
      services: [
        {
          service: "Mobile App Development",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "SEO",
      services: [
        {
          service: "AI SEO Optimization",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Product SEO Optimization",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: false,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "Additional Services",
      services: [
        {
          service: "MSME Registration",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Reports & Analytics",
          plans: {
            silver: {
              available: true,
              description: "Monthly reports",
            },
            gold: {
              available: true,
              description: "Weekly reports",
            },
            platinum: {
              available: true,
              description: "Daily reports",
            },
          },
        },
        {
          service: "Video Call Meetings",
          plans: {
            silver: {
              available: true,
              description: "1 call/month",
            },
            gold: {
              available: true,
              description: "1 call/month",
            },
            platinum: {
              available: true,
              description: "1 week/month",
            },
          },
        },
        {
          service: "Agreements",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Color Psychology Chart",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Customer Support",
          plans: {
            silver: {
              available: true,
              description: "within 24 hours",
            },
            gold: {
              available: true,
              description: "wiithin 1 hour",
            },
            platinum: {
              available: true,
              description: "instant support",
            },
          },
        },
        {
          service: "Personal Manager",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: false,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "Freebies Section",
      services: [
        {
          service: "USER Dashboard",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Payment Gateway Integration",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "MSME Registration",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Custom Domain Setup",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Backups",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "SSL Certificate",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Agreement Drafting",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Color Psychology Chart",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Customer Support",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
  ];

  const [hovered, setHovered] = useState(false);
  const bottomToTopVariant = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };
  const navigate = useNavigate();

  const handlePlanClick = () => {
    navigate("/choose-plan");
  };

  const [showAllFeatures, setShowAllFeatures] = useState(false);
  const [showPaymentTerms, setShowPaymentTerms] = useState(false);

  const toggleShowFeatures = () => {
    setShowAllFeatures((prev) => !prev);
  };

  const togglePaymentTermsPopup = () => {
    setShowPaymentTerms(!showPaymentTerms);
  };

  const getFeaturesForPlan = (planType) => {
    if (!featuresData || !Array.isArray(featuresData)) {
      return [];
    }

    const features = featuresData.flatMap((category) => {
      return category.services.map((service) => {
        const currentPlan = service.plans[planType];
        return {
          headingName: category.heading,
          serviceName: service.service,
          available: currentPlan.available,
          description: currentPlan.description,
        };
      });
    });

    if (!showAllFeatures) {
      const filteredFeatures = [];
      const uniqueHeadings = new Set();

      for (let i = 0; i < features.length; i++) {
        const feature = features[i];
        if (
          uniqueHeadings.size < 2 ||
          uniqueHeadings.has(feature.headingName)
        ) {
          filteredFeatures.push(feature);
          uniqueHeadings.add(feature.headingName);
        }
      }

      return filteredFeatures;
    }

    return features;
  };

  return (
    <>
      <motion.section
        className="container my-5 mb-0 d-flex flex-column align-items-center justify-content-start"
        initial="hidden"
        whileInView="visible"
        variants={bottomToTopVariant}
        viewport={{ once: true }}
      >
        <h2
          className="text-center display-4 fw-bold"
          style={{ color: "#26017B" }}
        >
          PLANS
        </h2>
        <p
          className="text-center fs-5 mb-5 fw-bold d-none d-md-block"
          style={{ color: "#00a5e0" }}
        >
          Where imagination meets strategy, crafting compelling <br /> campaigns
          to elevate your brand's story
        </p>

        {/* React Slick Slider Wrapper */}
        <Slider
          {...settings}
          className="row justify-content-center mb-2"
          style={{ maxWidth: "1200px" }}
        >
          {plansData.map((plan) => (
            <div
              key={plan.id}
              className="col-lg-4 col-md-6 mb-4 d-flex justify-content-center"
            >
              {/* Set the column size for larger screens and center the card */}
              <div
                className="card p-4 h-100"
                style={{
                  border: "3px solid #26017b",
                  width: "400px",
                  margin: "10px",
                }} // Add margin here
              >
                <h3 className="card-title fw-bold" style={{ color: "#00a5e0" }}>
                  {plan.title}
                </h3>
                <p className="card-text fw-bold" style={{ color: "#00a5e0" }}>
                  {plan.description}
                </p>
                <div className="card-body p-2 mt-3">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div>
                      <p
                        className=" fw-bold text-decoration-line-through mb-0"
                        style={{
                          whiteSpace: "nowrap",
                          color: "rgba(38, 1, 123, 0.32)",
                        }}
                      >
                        Rs. {plan.price}/-
                      </p>
                      <p
                        className="fw-bold fs-4 me-3 mb-0"
                        style={{ color: "#00a5e0", whiteSpace: "nowrap" }}
                      >
                        Rs. {plan.discountedPrice}
                        <span style={{ fontSize: "15px" }}>/month</span>
                      </p>
                      <p
                        className=" fw-bold  mb-0"
                        style={{
                          whiteSpace: "nowrap",
                          color: "#26017b",
                        }}
                      >
                        +1 month free*
                      </p>
                    </div>
                    <span
                      className="text-white rounded px-2 py-1 fw-bold align-items-center"
                      style={{ backgroundColor: "#00a5e0" }}
                    >
                      {plan.discount}%
                    </span>
                  </div>
                  <div className="d-flex justify-content-center mb-2">
                    <a
                      href="/choose-plan"
                      className="btn fs-5 fw-bold align-items-center px-4"
                      style={{
                        borderRadius: "30px",
                        border: "2px solid #00a5e0",
                        color: "#00a5e0",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#00a5e0";
                        e.currentTarget.style.color = "white";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "white";
                        e.currentTarget.style.color = "#00a5e0";
                      }}
                    >
                      Choose Plan
                    </a>
                  </div>
                  <div
                    className="line my-3"
                    style={{ height: "1px", backgroundColor: "#D9D9D9" }}
                  ></div>
                  <div className="features">
                    {getFeaturesForPlan(plan.id).map((feature, index) => (
                      <div key={index} className="mb-2">
                        {index === 0 ||
                        feature.headingName !==
                          getFeaturesForPlan(plan.id)[index - 1].headingName ? (
                          <h4 className="fw-bold" style={{ color: "#00a5e0" }}>
                            {feature.headingName}
                          </h4>
                        ) : null}
                        <p
                          className={`service-name ${
                            feature.available
                              ? "fw-bold"
                              : "text-decoration-line-through"
                          }`}
                          style={{
                            color: feature.available
                              ? "#26017b"
                              : "rgba(38, 1, 123, 0.32)",
                          }}
                        >
                          {/* Feature name goes here */}

                          {feature.serviceName}
                          {feature.description && (
                            <span
                              className="info-icon ms-2"
                              title={feature.description}
                            >
                              <i className="fas fa-info-circle text-info"></i>
                            </span>
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={toggleShowFeatures}
                    className="btn fw-bold btn-link"
                    style={{ textDecoration: "none", color: "#00a5e0" }}
                  >
                    {showAllFeatures ? (
                      <>
                        See Less Features <i className="fas fa-chevron-up"></i>
                      </>
                    ) : (
                      <>
                        See All Features <i className="fas fa-chevron-down"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </motion.section>
      <div className="text-center mt-0 mb-5">
        <h4
          onClick={handleShow}
          className="fw-bold"
          style={{ cursor: "pointer", color: "#26017b" }}
        >
          Payment Terms*
        </h4>

        {/* Bootstrap Modal */}
        {showModal && (
          <div
            className="modal fade show d-block"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered"
              role="document"
              style={{ maxWidth: "800px" }} // Increase the width of the modal
            >
              <div className="modal-content">
                <div className="modal-header" style={{ borderBottom: "none" }}>
                  <h5
                    className="modal-title text-center align-items-center fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Payment Terms
                  </h5>
                </div>
                <div className="modal-body text-center fw-bold">
                  {/* Centering the content */}
                  <p style={{ color: "#00a5e0", textAlign: "justify" }}>
                    By selecting a plan — Silver Streak, Golden Glow, or
                    Platinum Power — you agree to the pricing and payment terms
                    specified in your service agreement or invoice. Payments
                    must be made in full and according to the established
                    schedule.{" "}
                    <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                      Ads
                    </span>{" "}
                    – operates under the waterfall model, meaning each phase of
                    your service will proceed sequentially based on timely
                    payment. Failure to meet payment deadlines may result in
                    service suspension or termination. For convenience, we
                    accept a variety of payment options, including credit/debit
                    cards, bank transfers, and digital wallets. Please be
                    advised that all sales are final, with refunds only
                    available within 24 hours of plan purchase.
                  </p>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center align-items-center"
                  style={{ borderTop: "none" }}
                >
                  <button
                    type="button"
                    className="btn fw-bold"
                    style={{
                      backgroundColor: "#26017b",
                      border: "2px solid #26017b",
                      borderRadius: "5px",
                      color: "white",
                    }}
                    onClick={handleClose}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "white";
                      e.currentTarget.style.color = "#26017b";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "#26017b";
                      e.currentTarget.style.color = "white";
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PricingCards;
