import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/signup.css";
import SignupBro from "../assets/Signup-bro.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import googgleicon from "../assets/icons/google.png";
import loaderGif from "../assets/fuzzads-loader.gif"; // Import the loader image

const SignupPage = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [loading, setLoading] = useState(false); // State to manage loading
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    businessName: "",
    email: "",
    mobile: "",
    password: "",
  });

  const { firstName, lastName, businessName, email, mobile, password } =
    formData;

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!isTermsChecked) {
      alert("Please agree to the Terms and Conditions before signing up.");
      return;
    }

    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        firstName,
        lastName,
        businessName,
        email,
        mobile,
        password,
      });

      const res = await axios.post(
        "https://fuzzads-main-server.onrender.com/api/auth/signup",
        body,
        config
      );

      console.log("User registered successfully:", res.data);

      // Store user initials in local storage
      localStorage.setItem(
        "userInitials",
        `${firstName.charAt(0)}${lastName.charAt(0)}`
      );

      // Redirect to homepage
      navigate("/");
    } catch (err) {
      console.error("Error registering user:", err.response.data);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    window.location.href =
      "https://fuzzads-main-server.onrender.com/api/auth/google"; // Trigger Google OAuth
  };

  return (
    <div className="fullscreen-container">
      {loading && (
        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50">
          <img
            src={loaderGif}
            alt="Loading..."
            style={{ width: "250px", height: "250px" }}
          />
        </div>
      )}

      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div
          className="row w-100 justify-content-center"
          style={{
            borderRadius: "20px",
            overflow: "hidden",
            marginTop: "10rem",
          }}
        >
          {/* Left Signup Section */}
          <div
            className="col-12 col-md-5 p-3"
            style={{
              backgroundColor: "#00a5e0",
              borderTopLeftRadius: "20px",
              borderBottomLeftRadius: "20px",
            }}
          >
            <h2 className="fw-bold text-white text-center">REGISTER</h2>
            <p className="fw-bold text-white text-center fs-4">
              Ready to Boost up your Business
            </p>

            <form onSubmit={onSubmit} className="w-100">
              <div className="mb-3">
                <label
                  htmlFor="first-name"
                  className="form-label fw-bold text-white fs-5"
                >
                  Name
                </label>
                <div className="d-flex gap-2">
                  <input
                    type="text"
                    id="first-name"
                    name="firstName"
                    placeholder="Enter First Name"
                    value={firstName}
                    onChange={onChange}
                    required
                    className="form-control"
                    style={{ borderRadius: "20px" }}
                  />
                  <input
                    type="text"
                    id="last-name"
                    name="lastName"
                    placeholder="Enter Last Name"
                    value={lastName}
                    onChange={onChange}
                    required
                    className="form-control"
                    style={{ borderRadius: "20px" }}
                  />
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="business"
                  className="form-label fw-bold text-white fs-5"
                >
                  Business Name
                </label>
                <input
                  type="text"
                  id="business"
                  name="businessName"
                  placeholder="Enter Your Business Name"
                  value={businessName}
                  onChange={onChange}
                  required
                  className="form-control"
                  style={{ borderRadius: "20px" }}
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="form-label fw-bold fs-5 text-white"
                >
                  Email ID
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter Your Email ID"
                  value={email}
                  onChange={onChange}
                  required
                  className="form-control"
                  style={{ borderRadius: "20px" }}
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="mobile"
                  className="form-label fw-bold text-white fs-5"
                >
                  Mobile Number
                </label>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  placeholder="Enter Your Mobile Number"
                  value={mobile}
                  onChange={onChange}
                  required
                  className="form-control"
                  style={{ borderRadius: "20px" }}
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="form-label fw-bold text-white fs-5"
                >
                  Create a Password
                </label>
                <div className="input-group">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Enter your Password"
                    value={password}
                    onChange={onChange}
                    required
                    className="form-control"
                    style={{ borderRadius: "20px" }}
                  />
                  <button
                    type="button"
                    className="btn "
                    onClick={togglePasswordVisibility}
                    style={{ border: "none", color: "white" }}
                  >
                    {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>
              </div>
              <div className="d-flex align-items-center mt-3">
                <input
                  type="checkbox"
                  id="terms"
                  checked={isTermsChecked}
                  onChange={(e) => setIsTermsChecked(e.target.checked)}
                  className="form-check-input me-2 mb-2"
                />
                <label htmlFor="terms" className="form-check-label ">
                  <a
                    href="/terms-and-conditions"
                    className="text-white fw-bold text-decoration-none"
                  >
                    Terms & Conditions*
                  </a>
                </label>
              </div>

              <button
                type="submit"
                className="btn w-100 my-3 fw-bold mb-1"
                disabled={!isTermsChecked}
                style={{
                  backgroundColor: "white",
                  border: "2px solid white",
                  borderRadius: "20px",
                  color: "#00a5e0",
                }}
              >
                Sign in to <span style={{ color: "#26017b" }}>Fuzz</span>Ads
              </button>

              {/*<p className="text-center fw-bold text-white mb-1">OR</p>

              <button
                type="submit"
                className="btn btn-light w-100 d-flex align-items-center justify-content-center fw-bold mb-4"
                style={{ borderRadius: "20px" }}
              >
                <img
                  src={googgleicon}
                  alt="google"
                  className="me-2"
                  style={{ width: "20px", height: "auto" }}
                />{" "}
                Continue with Google
              </button>*/}

              <p className="text-center fw-bold text-white mt-2">
                Already have an account on FuzzAds?{" "}
                <a
                  href="/login"
                  className="text-decoration-none"
                  style={{ color: "#26017b" }}
                >
                  Login Now
                </a>
              </p>
            </form>
          </div>

          {/* Right Signup Section */}
          <div
            className="col-12 col-md-5 d-none d-md-flex justify-content-center align-items-start bg-light"
            style={{
              borderBottomRightRadius: "20px",
              borderTopRightRadius: "20px",
            }}
          >
            <div className="login-content text-center">
              {" "}
              {/* Center the content */}
              <h2 className="company fw-bold mb-5" style={{ fontSize: "6rem" }}>
                <span style={{ color: "#26017b" }}>Fuzz</span>
                <span style={{ color: "#00a5e0" }}>Ads</span>
              </h2>
              <img
                className="mt-5 mb-4"
                src={SignupBro}
                alt="Signupbro"
                style={{ maxWidth: "100%", height: "auto" }}
              />
              <h3 className="fw-bold fs-1" style={{ color: "#00a5e0" }}>
                Register Now!!!
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
