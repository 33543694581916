import React from "react";
import "../styles/main.css";
import SignupPage from "../components/SignupComponent";

const SignupMainPage = () => {
  return (
    <>
      <div class="content-container">
        <section class="signup" style={{backgroundColor: "#6CD8FF", height: '1000px'}}>
          <SignupPage />
        </section>
      </div>
    </>
  );
};

export default SignupMainPage;
