import React, { useState } from "react";

import rafting from "../assets/icons/rafting.png";
import loader from "../assets/fuzzads-loader.gif";

const PlanForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [messageVisible, setMessageVisible] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const response = await fetch(
        "https://fuzzads-main-server.onrender.com/api/customplan/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Network response was not ok");
      }
  
      setSubmissionMessage(data.message);
      setMessageVisible(true);
  
      // Clear form fields after successful submission
      setFormData({
        serviceCategory: "", // reset as needed or keep initial value
        fullName: "",
        email: "",
        phone: "",
        message: "",
      });
  
      setTimeout(() => {
        setMessageVisible(false);
      }, 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionMessage("Failed to submit form.");
      setMessageVisible(true);
  
      setTimeout(() => {
        setMessageVisible(false);
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="container mt-5">
      {/* Loading Overlay */}
      {isLoading && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1050,
          }}
        >
          <img src={loader} alt="Loading..." style={{width: '150px'}} />
        </div>
      )}

      <div className="row">
        {/* Left Side: Form Section */}
        <div className="col-12 mb-4 col-md-6">
          <h2 className="text-left fw-bold" style={{ color: "#26017b" }}>
          Want a Custom Service Plan? Tell us!!
          </h2>
          <form onSubmit={handleSubmit}>
            {/* Form fields here */}
            {/* Name Field */}
            <div className="mb-3">
              <label
                className="form-label fw-bold"
                style={{ color: "#26017b" }}
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Your Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                style={{
                  border: "2px solid #00a5e0",
                  borderRadius: "20px",
                }}
              />
            </div>
            {/* Email Field */}
            <div className="mb-3">
              <label
                className="form-label fw-bold"
                style={{ color: "#26017b" }}
              >
                Email
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Your Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                style={{
                  border: "2px solid #00a5e0",
                  borderRadius: "20px",
                }}
              />
            </div>
            {/* Phone Number Field */}
            <div className="mb-3">
              <label
                className="form-label fw-bold"
                style={{ color: "#26017b" }}
              >
                Phone Number
              </label>
              <input
                type="tel"
                className="form-control"
                placeholder="Your Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                style={{
                  border: "2px solid #00a5e0",
                  borderRadius: "20px",
                }}
              />
            </div>
            {/* Message Field */}
            <div className="mb-3">
              <label
                className="form-label fw-bold"
                style={{ color: "#26017b" }}
              >
                Message
              </label>
              <textarea
                className="form-control"
                placeholder="Your Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows={5}
                style={{
                  border: "2px solid #00a5e0",
                  borderRadius: "20px",
                }}
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="btn"
              style={{
                backgroundColor: "#26017b",
                color: "white",
                border: "2px solid #26017b",
                borderRadius: "20px",
                fontWeight: "bolder",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "white";
                e.currentTarget.style.color = "#26017b";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#26017b";
                e.currentTarget.style.color = "white";
              }}
            >
              Unlock The Best Deal
            </button>
          </form>

          {/* Display the response message */}
          {messageVisible && (
            <div className="alert alert-info" style={{ marginBottom: "20px" }}>
              {submissionMessage}
            </div>
          )}
        </div>

        {/* Right Side: Image Section */}
        <div className="col-12 mb-4 col-md-6 d-none d-md-block">
          <img
            src={rafting}
            alt="rafting"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "20px",
              marginTop: "2rem",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PlanForm;
