import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import

const MobileForm = () => {
  const [mobile, setMobile] = useState('');
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send mobile number and terms acceptance to your server
    const response = await fetch('/api/update-mobile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mobile }),
    });

    if (response.ok) {
      // Redirect to the homepage on success
      navigate('/'); // Use navigate instead of history.push
    } else {
      // Handle error, e.g., show a message
      console.error('Failed to update mobile number');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Mobile Number:</label>
        <input
          type="text"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          required
        />
      </div>
      <div>
        <input type="checkbox" required /> I agree to the terms and conditions
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default MobileForm;
