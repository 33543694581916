import React from "react";
import "../styles/main.css";
import Navbar from "../components/Navbar";
import CustomThankYou from "../components/CustomThankYou";
import Footer from "../components/Footer";

const CustomThankYouPage = () => {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <div class="content-container">
        <section class="thankyou">
          <CustomThankYou />
        </section>
      </div>
      <footer className="footer">
        <Footer />
      </footer>
    </>
  );
};

export default CustomThankYouPage;
