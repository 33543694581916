import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is imported
import "../styles/about.css";

const About = () => {
  return (
    <div className="about-main container-fluid min-vh-100 bg-light">
      <div className="row justify-content-center py-5 mt-5">
        <div
         className="col-lg-10 col-md-10 col-sm-12 bg-white p-4 shadow"
          style={{ borderRadius: "20px" }}
        >
          <h1 className="fw-bold fs-2 text-center mb-4 mt-4">About Us</h1>
          <p>
            Welcome to{" "}
            <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
            <span style={{ fontWeight: "bold", color: "#00a5e0" }}>Ads</span> –
            a cutting-edge advertising agency dedicated to helping businesses
            grow through innovative digital solutions. At
            <span style={{ fontWeight: "bold", color: "#26017b" }}> Fuzz</span>
            <span style={{ fontWeight: "bold", color: "#00a5e0" }}>Ads</span>,
            we understand that the digital landscape is constantly evolving, and
            we are here to provide you with the tools and strategies to stand
            out in a competitive market. ting, website development, software
            development and branding solutions.
          </p>
          <h3 className="fw-bold fs-4 mb-4 mt-4">Who We Are</h3>
          <p>
            Founded by <b>Pratham Choudhary (CEO)</b>,{" "}
            <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
            <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
              Ads
            </span>{" "}
            started with a mission to deliver personalized, result-driven
            digital marketing and advertising solutions for businesses of all
            sizes. With our headquarters rooted in Greater Noida, U.P. India, we
            operate remotely to serve clients across the globe. As a
            full-service digital marketing agency, we specialize in social media
            management, website development, logo and graphic design, SEO, and
            more.
          </p>
          <p>
            Our talented team, led by <b>Unnati Samaiyar (Co-founder)</b> who
            heads the development department, brings together industry experts
            passionate about technology, creativity, and business growth. We
            work collaboratively with our clients to provide innovative
            solutions tailored to meet their specific needs.
          </p>
          <h3 className="fw-bold fs-4 mb-4 mt-4">Our Mission</h3>
          <p>
            At{" "}
            <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
            <span style={{ fontWeight: "bold", color: "#00a5e0" }}>Ads</span>,
            our mission is simple – to{" "}
            <b>empower businesses through strategic digital solutions</b> that
            drive results. We believe in the power of data, creativity, and
            technology to build brands, connect with audiences, and generate
            sustainable business growth. We are committed to delivering
            measurable success while maintaining transparency, integrity, and
            customer-centricity.
          </p>

          <h3 className="fw-bold fs-4 mb-4 mt-4">Our Services</h3>
          <p>
            We offer a wide range of services designed to boost your brand’s
            visibility and performance in the digital space:
          </p>
          <ol>
            <li className="fw-bold">Website Development</li>
            <p>
              We craft visually appealing, fully responsive websites that are
              tailored to your business needs. Whether you need a simple website
              or a fully customized solution with advanced integrations, our
              team will deliver a seamless user experience that drives
              conversions.
            </p>
            <li className="fw-bold">Social Media Management</li>
            <p>
              Managing your brand’s presence on social media can be
              overwhelming, but we’re here to make it easy. From creating
              engaging content to managing social media platforms like
              Instagram, Facebook, LinkedIn, X (Twitter), and YouTube, we ensure
              your brand connects with the right audience.
            </p>
            <li className="fw-bold">Graphic Design</li>
            <p>
              Our graphic design services include creating stunning logos,
              visiting cards, banners, social media posts, email signatures,
              prospectus, and brand videos. We bring your vision to life with
              designs that are not only visually striking but also aligned with
              your brand identity.
            </p>
            <li className="fw-bold">Digital Marketing & SEO</li>
            <p>
              Our digital marketing services, including search engine
              optimization (SEO), ensure that your business ranks high on search
              engines, reaching potential customers where they are searching. We
              use advanced AI-based SEO solutions for Platinum Power plans to
              maximize your visibility and drive traffic.
            </p>
            <li className="fw-bold">Advertising Services</li>
            <p>
              From Google Ads, Meta Ads (Facebook & Instagram), LinkedIn Ads, to
              X (Twitter) Ads, we offer targeted advertising solutions designed
              to deliver high ROI. Our expert team optimizes every ad campaign
              based on performance, ensuring maximum impact for your ad spend.
            </p>
            <li className="fw-bold">App Development</li>
            <p>
              For businesses looking to expand their digital footprint, we
              provide comprehensive app development services. Available in our
              Platinum Power plan, we build intuitive and robust mobile
              applications to support your business operations and enhance
              customer engagement.
            </p>
            <li className="fw-bold">WhatsApp & YouTube Management</li>
            <p>
              We also specialize in managing WhatsApp Business accounts and
              YouTube channels. From setting up broadcast channels to uploading
              content, we help you make the most of these platforms, ensuring
              growth and engagement.
            </p>
            <li className="fw-bold">Custom Business Emails & Hosting</li>
            <p>
              <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
              <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
                Ads
              </span>{" "}
              offers custom business email setup, hosting services, domain
              management, and SSL certificates in collaboration with Hostinger.
              You’ll have a professional email system and secure website
              infrastructure to support your business’s digital operations.
            </p>
            <li className="fw-bold">MSME Registration & Business Consulting</li>
            <p>
              For businesses in India, we assist with E-Udyam MSME registration,
              ensuring that your business meets government regulations while
              unlocking potential benefits and incentives. Our business
              consulting services provide additional strategic insights into
              growing your brand.
            </p>
          </ol>
          <h3 className="fw-bold fs-4 mb-4 mt-4">Why Choose Us</h3>
          <ul>
            <li>
              <b>Tailored Solutions:</b> Every business is unique, and so are
              our solutions. We listen to your needs and craft strategies that
              suit your specific goals.
            </li>
            <li>
              <b>Data-Driven Approach:</b> We use insights and analytics to
              inform our decisions, ensuring that every campaign we run is
              optimized for success.
            </li>
            <li>
              <b>Experienced Team:</b> Our team consists of experts from
              different fields, including digital marketing, web development,
              and design, bringing diverse skills to every project.
            </li>
            <li>
              <b>Transparent & Affordable Pricing:</b> We believe in
              transparency. Our plans are clear and competitive, with no hidden
              costs. Whether you choose our Silver Streak, Golden Glow, or
              Platinum Power plans, you’ll know exactly what you’re paying for.
            </li>
            <li>
              <b>Personalized Support:</b> We assign a Personal Manager to every
              client, ensuring a smooth and responsive experience.
            </li>
            <li>
              <b>Innovation with AI:</b> We leverage the latest technologies,
              including AI integration for SEO, analytics, and customer
              engagement to provide next-level solutions for Platinum Power
              clients.
            </li>
          </ul>
          <h2 className="fw-bold fs-3 mb-2 mt-4">Our Plans</h2>
          <p>We offer three flexible plans to suit businesses of all sizes:</p>
          <h4 className="fw-bold fs-5 mb-1 mt-4">Silver Streak</h4>
          <ul>
            <li>
              Best for small businesses starting out in the digital space.
            </li>
            <li>
              Includes basic website development, limited social media
              management, and one-off graphic design services.
            </li>
          </ul>
          <h4 className="fw-bold fs-5 mb-1 mt-4">Golden Glow</h4>
          <ul>
            <li>Our most popular plan, ideal for growing businesses.</li>
            <li>
              Includes more advanced features, social media platform management,
              YouTube support, and expanded advertising options.
            </li>
          </ul>
          <h4 className="fw-bold fs-5 mb-1 mt-4">Platinum Power</h4>
          <ul>
            <li>
              Designed for large businesses or enterprises seeking full
              customization and advanced services.
            </li>
            <li>
              Includes AI-based SEO, custom app development, in-depth analytics,
              and more.
            </li>
          </ul>
          <h2 className="fw-bold fs-3 mb-2 mt-4">Our Team</h2>
          <p>
            At{" "}
            <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
            <span style={{ fontWeight: "bold", color: "#00a5e0" }}>Ads</span>,
            we take pride in our collaborative team of professionals who work
            behind the scenes to make your business succeed. From designers,
            developers, and marketers to client support experts, we are a
            diverse team passionate about bringing your vision to life.
          </p>
          <h2 className="fw-bold fs-3 mb-2 mt-4">Client Testimonials</h2>
          <p>
            While we are still building our portfolio of clients, we’ve had the
            privilege of working with a few exceptional businesses who’ve
            experienced the{" "}
            <span style={{ fontWeight: "bold", color: "#26017b" }}>Fuzz</span>
            <span style={{ fontWeight: "bold", color: "#00a5e0" }}>
              Ads
            </span>{" "}
            difference. Stay tuned for their success stories, and let us add
            your business to the growing list!
          </p>
          <h2 className="fw-bold fs-3 mb-2 mt-4">Our Values</h2>
          <p>We are driven by the following core values:</p>
          <ul>
            <li>
              <b>Creativity:</b> We believe in thinking outside the box to
              deliver innovative solutions.
            </li>
            <li>
              <b>Integrity:</b> Honesty and transparency guide everything we do.
            </li>
            <li>
              <b>Excellence:</b> We strive for the highest quality in all our
              services and client interactions.
            </li>
            <li>
              <b>Client-Centricity:</b> Our clients are our priority, and their
              success fuels our passion.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About;
